<template>
  <div>
    <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-form @submit.prevent="sendCreateFixture">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col>
                  <v-select v-model="fixture.competition" :items="getCompetitions" item-text="name" return-object
                    label="Competition"
                    @change="updateTeams(); updateMatchday(); fetchFixtures(fixture.competition.id);"></v-select>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.matchday" label="Matchday" type="number"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.id" disabled readonly label="Fixture ID"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select v-model="fixture.homeTeam" :items="getTeams" item-text="name" return-object
                    label="Home Team" clearable @click:clear="clearHome"></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="fixture.awayTeam" :items="getTeams" item-text="name" return-object
                    label="Away Team" clearable @click:clear="clearAway"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="fixture.venue" label="Stadium"
                    :hint="'e.g. Croke Park, Dublin'"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.stage" label="Competition"
                    hint="e.g. Leinster, Munster, All Ireland, Lory Meagher"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="fixture.round" label="Round"
                    hint="e.g. Semi Final, Round Robin"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.matchdayLabel" label="Matchday Label"
                    hint="e.g. Leinster Final, Round Robin 1"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="fixture.date" label="Date" type="date"></v-text-field>
                </v-col>
                <v-col>
                  <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="fixture.time" transition="scale-transition" offset-y max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fixture.time" label="Kick off time"
                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="menu2" v-model="fixture.time" full-width
                      @click:minute="$refs.menu.save(fixture.time)"></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select v-model="fixture.inPlay"
                    :items="[{ text: 'True', value: true }, { text: 'False', value: false }]"
                    label="In Play"></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="fixture.coverage"
                    :items="[{ text: 'RTE 1', value: 'rte1' }, { text: 'RTE 2', value: 'rte2' }, { text: 'GAA Go', value: 'gaago' }, { text: 'No live coverage', value: null }]"
                    label="TV Coverage"></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>
                  Fixture Details - {{ fixture.competition ? fixture.competition.name : 'Select competition' }}
                </v-card-title>
                <v-card-text>
                  <div v-for="fixture in activeFixtures" :key="fixture.id">
                    <v-row>
                      <v-col>{{ isObject(fixture.homeTeam) ? fixture.homeTeam.name : fixture.homeTeam }} vs {{ isObject(fixture.awayTeam) ? fixture.awayTeam.name : fixture.awayTeam }}</v-col>
                      <v-col>{{ fixture.venue }}</v-col>
                      <v-col>{{ fixture.matchdayLabel }}, {{ fixture.stage }}</v-col>
                      <v-col>{{ fixture.time }}, {{ fixture.date }}</v-col>
                      <v-col>{{ fixture.coverage }} <v-btn small @click="editFixture(fixture)">Edit</v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary">Save Fixture</v-btn>
            </v-col>
            <v-col>
              <v-btn @click="clearFixture(false)">Clear Fixture</v-btn>
            </v-col>
            <v-col>
              <v-btn @click="clearFixture(true)">Clear All</v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      fixture: {
        competition: {},
        homeTeam: '',
        awayTeam: '',
        venue: '',
        date: '',
        matchday: '',
        stage: '',
        time: '',
        inPlay: true,
        coverage: null,
        round: '',
        matchdayLabel: ''
      },
      menu2: false,
      loading: false
    };
  },
  mounted() {
    this.fetchCompetitions();
  },
  methods: {
    ...mapActions(["fetchCompetitions", "fetchTeams", "createFixture", "fetchFixtures"]),
    async sendCreateFixture() {
      this.loading = true;
      let resp = await this.createFixture(this.fixture);
      if (resp) {
        this.loading = false;
        this.fixture = {
          competition: this.getCompetitions.find(it => it.id == this.fixture.competition.id),
          homeTeam: '',
          awayTeam: '',
          venue: '',
          date: this.fixture.date,
          matchday: this.fixture.matchday,
          stage: '',
          time: this.fixture.time,
          inPlay: this.fixture.inPlay,
          coverage: null,
          round: '',
          matchdayLabel: ''
        }
      }
    },
    clearHome() {
      this.fixture.homeTeam = "";
    },
    clearAway() {
      this.fixture.awayTeam = "";
    },
    updateTeams() {
      this.fetchTeams(this.fixture.competition.sport);
    },
    updateMatchday() {
      this.fixture.matchday = this.fixture.competition.currentMatchday + 1
    },
    editFixture(fixture) {
      let uiDateFormat = fixture.date.split('/').map(part => part.padStart(2, '0')).reverse().join('-');
      this.fixture = fixture;
      this.fixture.date = uiDateFormat;
      this.fixture.competition = this.getCompetitions.find(it => it.name == this.fixture.competition)
    },
    clearFixture(clearCompetition) {
      let competition = this.fixture.competition;
      let matchday = this.fixture.matchday;
      this.fixture = {
        competition: {},
        homeTeam: '',
        awayTeam: '',
        venue: '',
        date: '',
        matchday: '',
        stage: '',
        time: '',
        inPlay: false,
        coverage: null
      }
      if (!clearCompetition) {
        this.fixture.competition = competition;
        this.fixture.matchday = matchday;
      }
    },
    isObject(value){
      return value !== null && typeof value === 'object';
    }
  },
  computed: {
    ...mapGetters(["getCompetitions", "getTeams", "getFixtures"]),
    activeFixtures() {
      return this.getFixtures.filter(it => it.matchday == this.fixture.matchday)
    }
  }
};
</script>